.macintosh {
  display: block;
  color: black;
  width: 340px;
  height: 340px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  border-radius: 15px;
  background-color: #dddbc2;
  background-image: linear-gradient(
    #dddbc2,
    #dfdac4
  ); /* Fallback background for conic gradient */
  background-image: conic-gradient(
    #dddbc2 0 11.5%,
    #ececd5 12% 13.5%,
    #cecdaf 14% 38%,
    #c8c4a7 39% 61.5%,
    #c7c3a6 62% 87%,
    #dfdac4 87.25% 89%,
    #dddbc2 89.5%
  );
  transition: box-shadow 4s ease;
}

.monitor-inner {
  display: block;
  width: 310px;
  height: 300px;
  background-image: linear-gradient(#b6b4a5, #b8b78a);
  margin: auto;
  position: absolute;
  top: 22px;
  left: 0;
  right: 0;
  border-radius: 5px;
}

.screen-cutout {
  display: block;
  width: 280px;
  height: 225px;
  background-color: #dddbc2;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  border-radius: 5px;
  background-image: linear-gradient(#938f6a, #e0dfc3);
  background-image: conic-gradient(
    #938f6a 12.5%,
    #b5b293 15.5% 33%,
    #e0dfc3 34% 65.5%,
    #c2c1a2 66.5% 83.5%,
    #938f6a 86.5%
  );
}

.screen {
  display: block;
  width: 260px;
  height: 195px;
  background-color: #4f5555;
  color: #43ff43;
  text-shadow: 0 0 1px #43ff43;
  margin: auto;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  border-radius: 20px;
  box-shadow: 0 0 20px 10px #2b3030 inset;
}

.code {
  display: block;
  width: 236px;
  height: 171px;
  margin: 12px 12px;
  font-weight: 600;
  font-size: 8px;
  -webkit-text-size-adjust: 100%;
}

.logo {
  display: block;
  width: 22px;
  height: 22px;
  background-image: radial-gradient(#c9c6b5, #cbc9ba);
  border-radius: 2px;
  position: absolute;
  left: 22px;
  bottom: 22px;
  box-shadow: 0 0 2px 0px #979181 inset;
}

.opening {
  display: block;
  width: 155px;
  height: 15px;
  background-image: linear-gradient(#bebb9c, #cac8a7 74.5% 75.5%);
  background-image: conic-gradient(
    #bebb9c 0% 23.5%,
    #c7c2a2 24.5% 25%,
    #dcd8bd 26% 73.5%,
    #cac8a7 74.5% 75.5%,
    #bebb9c 76.5%
  );
  position: absolute;
  bottom: 35px;
  right: 21px;
  border-radius: 4px;
}

.opening-inner {
  display: block;
  width: 126px;
  height: 8px;
  background-color: #181914;
  border-radius: 2px;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 3.5px;
}

.cpduels-label {
  display: block;
  width: 90px;
  height: 10px;
  font-family: Georgia;
  text-align: center;
  position: absolute;
  bottom: 18px;
  right: 30px;
}
