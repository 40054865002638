.coffeeImage {
  height: 27px;
  box-shadow: none;
  border: none;
  vertical-align: middle;
}

.coffeeButtonText {
  margin-left: 10px;
  font-size: 0.8rem;
  vertical-align: middle;
}
