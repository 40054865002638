.phone {
  width: 300px;
  height: 615px;
  border-radius: 42px;
  box-shadow: 0 5px 25px;
  padding: 8px;
  background-color: #111111;
  font-family: "Rubik", sans-serif;
}

.phone-inner {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  position: relative;
  background-color: #282b30;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.phone-front-camera {
  position: absolute;
  top: -1px;
  left: 67px;
  width: 150px;
  height: 31px;
  background-color: #111111;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.phone-screen-top {
  position: relative;
  height: 90px;
  z-index: 10;
}

.phone-screen-left-arrow {
  position: absolute;
  top: 52px;
  left: 16px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-right: 14px solid rgb(255, 255, 255, 0.5);
  border-bottom: 12px solid transparent;
}

.phone-screen-left-arrow::after {
  position: absolute;
  content: "";
  top: -12px;
  left: 5px;
  border-top: 12px solid transparent;
  border-right: 14px solid #282b30;
  border-bottom: 12px solid transparent;
}

.phone-screen-header {
  font-size: 1.2rem;
  font-weight: bold;
  color: #d2d2d2;
  width: fit-content;
  padding: 0;
  margin: 50px auto;
}

.phone-screen-middle {
  position: relative;
  background-color: #424549;
  flex: 1;
  overflow-y: hidden;
}

.phone-screen-content {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  z-index: 5;
  font-size: 15px;
  line-height: 18px;
}

.new-message-bar {
  position: relative;
  margin: 4px auto;
  width: 270px;
  height: 2px;
  background-color: #df1111;
}

.new-message-bar::after {
  position: absolute;
  content: "NEW";
  color: #df1111;
  font-weight: bold;
  left: 110px;
  top: -7px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #424549;
}

.phone-screen-content-message {
  height: fit-content;
  padding: 2px 8px;
  color: #d2d2d2;
  max-width: 280px;
  word-wrap: true;
}

.phone-screen-content-message .highlight {
  color: #7d7dff;
  text-decoration: underline;
}

.phone-screen-content-message .author {
  font-weight: bold;
}

.phone-screen-bottom {
  height: 90px;
  position: relative;
  z-index: 10;
}

.phone-screen-input-section {
  display: flex;
  justify-content: center;
  margin: 10px 8px;
  align-items: center;
}

.phone-screen-input-area {
  position: relative;
  margin: 0 10px;
  padding: 2px;
  width: 250px;
  height: 30px;
  border: solid 1px rgb(0, 0, 0, 0.5);
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1e2124;
}

.phone-screen-input-area::before {
  position: absolute;
  content: "";
  left: -15px;
  top: -1px;
  width: 30px;
  height: 30px;
  border: solid 1px rgb(0, 0, 0, 0.5);
  border-radius: 100%;
  z-index: -1;
  background-color: #1e2124;
}

.phone-screen-input-area::after {
  position: absolute;
  content: "";
  right: -15px;
  top: -1px;
  width: 30px;
  height: 30px;
  border: solid 1px rgb(0, 0, 0, 0.5);
  border-radius: 100%;
  z-index: -1;
  background-color: #1e2124;
}

.phone-screen-input-area span {
  margin-left: -6px;
  color: #cfcfcf;
}

.phone-screen-send-button {
  display: flex;
  justify-content: center;
  color: #f3f3f3;
  background-color: #5151ff;
  width: 25px;
  height: 25px;
  padding: 2px;
  padding-left: 3px;
  padding-bottom: 3px;
  margin-right: -14px;
  font-size: 0.9rem;
  border-radius: 100%;
}

.phone-screen-typing-indicator {
  color: #cfcfcf;
  position: absolute;
  top: 40px;
  left: 20px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
}

.phone-screen-typing-indicator span {
  margin-left: 6px;
}

.typing-dot {
  float: left;
  width: 6px;
  height: 6px;
  margin: 0 2px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
}

.typing-dot:nth-child(1) {
  animation-delay: 0s;
}

.typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.phone-screen-bottom-line {
  position: absolute;
  bottom: 5px;
  left: 95px;
  height: 5px;
  width: 110px;
  border-radius: 4px;
  background-color: #111111;
}
