.property-title {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: capitalize;
}

.time-limit {
  width: 6.5em;
  margin-right: 3em;
  white-space: nowrap;
  overflow-x: hidden;
}

.memory-limit {
  width: 8.5em;
  margin-right: 3em;
  white-space: nowrap;
  overflow-x: hidden;
}

.input-file {
  margin-right: 3em;
}

.problem-statement p, .problem-input-specifications p, .problem-output-specifications p, .problem-sample-test-cases p, .problem-note p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

li {
  margin-left: 1em;
}

/*sample-test-cases*/

.sample-tests .section-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5em;
}

.title {
  font-weight: bold;
}

.sample-test {
  display: inline-flex;
  align-items: stretch;
  gap: 0;
}

.sample-test .title {
  padding: 0.5em;
  text-align: center;
  border-bottom: solid 1px;
}

.sample-test .input {
  border: solid 1px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.sample-test .output {
  border: solid 1px;
  margin-left: -1px;
  margin-right: 1em;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.sample-test .input, .sample-test .output {
  width: fit-content;
}

.input-output-copier {
  display: none;
}

.sample-test .input pre, .sample-test .output pre {
  margin: 0 0.25em;
}

/*Note*/

.note .section-title {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5em;
}

.epigraph-text {
  font-style: italic;
  text-align: center;
}