.chat-box-typing-indicator {
  display: flex;
  align-items: center;
  margin-top: 0.2em;
  font-size: 0.8rem;
}

.chat-box-typing-indicator span {
  margin-left: 6px;
}

.chat-box-typing-dot {
  float: left;
  width: 6px;
  height: 6px;
  margin: 0 2px;
  background: #8d8c91;
  border-radius: 50%;
  opacity: 0;
  animation: chat-box-loadingFade 1s infinite;
}

.chat-box-typing-dot:nth-child(1) {
  animation-delay: 0s;
}

.chat-box-typing-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.chat-box-typing-dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes chat-box-loadingFade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}
